import React from "react";

export default ({ color = "white" }) => (
  <svg width="143" height="135" viewBox="0 0 143 135" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.7282 125.862C98.2469 122.508 105.699 109.726 102.373 97.3125C99.0466 84.8992 86.2018 77.5554 73.6832 80.9098C61.1645 84.2641 53.7125 97.0464 57.0386 109.46C60.3648 121.873 73.2095 129.217 85.7282 125.862Z" fill="#333333" />
    <path d="M100.641 111.037C110.883 108.292 116.98 97.834 114.259 87.6777C111.538 77.5213 101.028 71.5128 90.7858 74.2572C80.5432 77.0017 74.4461 87.4599 77.1675 97.6163C79.8889 107.773 90.3982 113.781 100.641 111.037Z" fill="#FFB000" />
    <path d="M105.749 61.4875C118.268 58.1331 125.72 45.3509 122.394 32.9375C119.067 20.5242 106.223 13.1804 93.7039 16.5348C81.1853 19.8891 73.7332 32.6714 77.0594 45.0848C80.3855 57.4981 93.2303 64.8418 105.749 61.4875Z" fill="#333333" />
    <path d="M120.662 46.6617C130.904 43.9172 137.001 33.459 134.28 23.3027C131.558 13.1463 121.049 7.13776 110.807 9.88224C100.564 12.6267 94.4669 23.0849 97.1883 33.2413C99.9097 43.3977 110.419 49.4062 120.662 46.6617Z" fill="#F76E9C" />
    <path d="M40.0661 79.0871C52.5848 75.7327 60.0368 62.9505 56.7106 50.5371C53.3845 38.1238 40.5397 30.78 28.0211 34.1344C15.5024 37.4888 8.05038 50.271 11.3765 62.6844C14.7027 75.0977 27.5474 82.4415 40.0661 79.0871Z" fill="#333333" />
    <path d="M54.9787 64.2613C65.2212 61.5168 71.3183 51.0586 68.5969 40.9023C65.8756 30.7459 55.3662 24.7374 45.1237 27.4818C34.8811 30.2263 28.784 40.6845 31.5054 50.8409C34.2268 60.9973 44.7361 67.0058 54.9787 64.2613Z" fill="#73A77B" />
  </svg>

);

